<template>
  <div>
    <router-link
      v-if="source && end"
      :to="`/${source}/${value}/${end}`"
    >
      <i class="fal fa-pen"></i>
    </router-link>
    <router-link
      v-else-if="source"
      :to="`/${source}/${value}`"
    >
      <i class="fal fa-pen"></i>
    </router-link>

    <router-link v-if="groupPage" :to="`/group/${value}`">
      <i class="fal fa-users"></i>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    params: Object,
    val: {
      required: false,
    },
    dest: {
      type: String,
      required: false,
    },
    res: {
      type: String,
      required: false,
    },
    group: {
      required: false,
    }
  },
  setup(props) {
    let source = props.dest || props.params.source;
    let value = props.val || props.params.value;
    let end = props.res || props.params.end;
    let groupPage = props.group || props.params?.groupPage;

    return {
      source,
      value,
      end,
      groupPage
    };
  },
};
</script>
